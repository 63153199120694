import { render, staticRenderFns } from "./contratoItem.vue?vue&type=template&id=38d7ba96&scoped=true"
import script from "./contratoItem.vue?vue&type=script&lang=js"
export * from "./contratoItem.vue?vue&type=script&lang=js"
import style0 from "./contratoItem.vue?vue&type=style&index=0&id=38d7ba96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d7ba96",
  null
  
)

export default component.exports